import { makeStyles } from "@material-ui/core/styles"
import ReactPlayer from "react-player"
import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import {
  clearWorkout,
  fetchWorkout,
  playerOnPause,
  playerOnPlay,
  videoPlaybackUpdate,
  waitRoomPlayClicked
} from "../soloSlice"
import useHeight from "../../videochat/hooks/useHeight/useHeight"
import StyledSnackbar from "../../../components/StyledSnackbar"
import BackdropLoader from "../../../components/BackdropLoader"
import { FadeInReview } from "../../review/ReviewWorkout"
import clsx from "clsx"
import CloseButton from "../../../components/CloseButton"
import { navigate } from "@reach/router"
import MuxDataTracker from "../../analytics/MuxDataTracker"
import useSyncPlayback from "../../videochat/hooks/useSyncPlayback/useSyncPlayback"
import { WORKOUTS_HOME } from "../../routing/Locations"
import { ClassWaitOverlay } from "../ClassWaitOverlay"
import VideoOverlay from "../VideoOverlay"
import { BGBlurImage } from "../../../components/BGBlurImage"


const useStyles = makeStyles((theme) => ({
  root: {
    outline: "none",
    width: "100%",
    display: "flex",
    height: "100vh",
    alignContent: "center",
    justifyContent: "center",
    justifyItems: "center",
    alignItems: "center",
    overflow: "hidden"
  },
  feedbackActive: {
    [`${theme.breakpoints.down("sm")} and (orientation: landscape)`]: {
      height: "auto",
      overflowY: "auto",
      alignContent: "start",
      alignItems: "start",
      margin: theme.spacing(2, 0)
    }
  },
  ":&focus": {
    outline: "none",
    border: "none"
  }
}))

export interface Props {
  id: string
  timeSlotId?: string
}

export default function SoloPlayer(props: Props) {
  const classes = useStyles()
  const soloState = useSelector((state: RootState) => state.solo)
  const numHausmates = useSelector((state: RootState) => state.live.numHausmates)
  const isLiveClass = useSelector((state: RootState) => state.live.isLive)
  const [startPositionAdjusted, setStartPositionAdjusted] = useState(true)
  const dispatch = useDispatch()
  const [setPlayedSeconds, setVideoDuration, reactPlayerRef, setSyncAllowed, setVideoStartTime] = useSyncPlayback(-1, 10)
  const [playVideo, setPlayVideo] = useState(false)
  const [playerReady, setPlayerReady] = useState(false)
  const [buffering, setBuffering] = useState(false)

  const height = useHeight()

  useEffect(() => {
    dispatch(fetchWorkout({ wid: props.id, timeSlotId: props.timeSlotId }))
  }, [props.id])

  useEffect(() => {
    const play = playerReady && soloState.playVideo
    setPlayVideo(play)
    setSyncAllowed(play)
  }, [soloState.playVideo, playerReady])

  useEffect(() => {
    const startTime = soloState.userWorkout?.workout?.scheduleInfo?.startTimeInMs
    if (startTime && startTime > 0) {
      setVideoStartTime(startTime)
      setSyncAllowed(true)
    }
  }, [soloState.userWorkout?.workout?.scheduleInfo?.startTimeInMs])

  useEffect(() => {
    return () => {
      dispatch(clearWorkout())
    }
  }, [])

  function onPlayerReady(reactPlayer: ReactPlayer) {
    const scheduleSync = (soloState.userWorkout?.workout.scheduleInfo?.startTimeInMs ?? 0) > 0
    if (!scheduleSync && startPositionAdjusted) {
      if (soloState.userWorkout?.playbackProgress && soloState.userWorkout?.playbackProgress > 0)
        reactPlayer.seekTo(soloState.userWorkout?.playbackProgress, "fraction")
      setStartPositionAdjusted(false)
    }
    setPlayerReady(true)
  }

  const rootStyle = clsx({
    [classes.root]: true,
    [classes.feedbackActive]: soloState.showFeedback
  })


  function playerWithTracker() {
    return <>
      <MuxDataTracker wid={soloState.userWorkout!.workout.id} streamType={isLiveClass ? "live" : "on-demand"}
                      playerName={"solo"} videoTitle={soloState.userWorkout!.workout.pageTitle} muxStream={soloState.userWorkout!.muxStream}/>
      {<ReactPlayer
        ref={reactPlayerRef}
        height={"100%"}
        controls={!isLiveClass}
        playing={playVideo}
        width={"100%"}
        volume={1}
        onPlay={() => {
          dispatch(playerOnPlay())
          setBuffering(false)
        }}
        onPause={() => dispatch(playerOnPause())}
        onBuffer={() => setBuffering(isLiveClass)}
        onBufferEnd={() => setBuffering(false)}
        onProgress={({ playedSeconds, played }) => {
          setPlayedSeconds(playedSeconds)
          setBuffering(false)
          dispatch(videoPlaybackUpdate({ playedSeconds: playedSeconds, playedRatio: played, videoVisible: true }))
        }}
        onReady={onPlayerReady}
        url={`${soloState.userWorkout!.streamLink}`}
        onDuration={setVideoDuration}
        config={{ file: { attributes: { controlsList: "nodownload" }, hlsOptions: {startPosition: soloState.playbackStartPosition, startLevel: soloState.userWorkout!.hlsStartLevel }}}}
      />}
      <BackdropLoader show={buffering}/>
    </>
  }

  return <div className={rootStyle} style={{ height: height }}>
    <BackdropLoader show={soloState.loading}/>
    {soloState.userWorkout && <BGBlurImage imageUrl={soloState.userWorkout?.workout.thumbnailUrl}
                                           localImage={soloState.userWorkout?.workout.localImage}/>}
    {soloState.showVideo && playerWithTracker()}
    {soloState.showVideo && isLiveClass && <VideoOverlay/>}
    {soloState.showWaitingRoom &&
    <ClassWaitOverlay workout={soloState.userWorkout!.workout} showPlay={soloState.showWaitRoomPlay ?? false}
                      countdown={soloState.countdown}
                      numHausmates={numHausmates}
                      playClicked={() => {
                        setPlayVideo(true)
                        dispatch(waitRoomPlayClicked())
                      }}/>}

    <FadeInReview show={soloState.showFeedback} workoutId={soloState.userWorkout?.workout.id ?? ""} isGroup={false}/>

    {soloState.error && <StyledSnackbar show={true} severity={"error"} message={soloState.error.message}/>}

    {(!isLiveClass || soloState.showWaitingRoom) && <CloseButton clicked={() => navigate(WORKOUTS_HOME)}/>}
  </div>
}
