import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import clsx from "clsx"

import ToggleFullScreenButton from "./ToggleFullScreenButton/ToggleFullScreenButton"

import EndClassButton from "./EndClassButton/EndCallButton"
import useIsUserActive from "../../videochat/Controls/useIsUserActive/useIsUserActive"
import TogglePlayButton from "./TogglePlayButton/TogglePlayButton"
import { useSelector } from "react-redux"
import { RootState } from "../../../redux/store"
import ShoutoutButton from "../../live/ShoutoutButton/ShoutoutButton"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: "flex",
      position: "absolute",
      bottom: "6px",
      left: "0px",
      right: "0px",
      margin: "auto",
      zIndex: 34,
      color: "#000000",
      maxWidth: "min-content",
      transform: "translate(0%, 30px)",
      transition: "opacity 1.2s, transform 1.2s, visibility 0s 1.2s",
      opacity: 0,
      visibility: "hidden",
      "&.showControls, &:hover": {
        transition: "opacity 0.6s, transform 0.6s, visibility 0s",
        opacity: 1,
        visibility: "visible",
        transform: "translate(0px, 0px)"
      },
      [theme.breakpoints.down("xs")]: {
        bottom: theme.spacing(1)
      }
    }
  })
)

export interface Props {
  wellnessClass: boolean
}


export default function Controls(props: Props) {
  const classes = useStyles()
  const isUserActive = useIsUserActive()
  const alwaysShowControls = useSelector((state: RootState) => state.solo.alwaysShowControls)
  const showControls = isUserActive || alwaysShowControls

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div className={clsx(classes.container, { showControls })}>
        {!props.wellnessClass && <ShoutoutButton/>}
        <ToggleFullScreenButton/>
        <EndClassButton/>
      </div>
    </div>
  )
}
