import React from "react"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Fab from "@material-ui/core/Fab"
import Tooltip from "@material-ui/core/Tooltip"
import { WORKOUTS_HOME } from "../../../routing/Locations"
import { navigate } from "gatsby"
import { ExitToApp } from "@material-ui/icons"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1, 1, 1, 0)
    }
  })
)

export default function EndCallButton() {
  const classes = useStyles()

  return (
    <Tooltip title={"Leave Class"} onClick={() => {
      navigate(WORKOUTS_HOME, { replace: true })
    }} placement="top"
             PopperProps={{ disablePortal: true }}>
      <Fab className={classes.fab} size={"small"}>
        <ExitToApp/>
      </Fab>
    </Tooltip>
  )
}
