import { makeStyles } from "@material-ui/core/styles"
import { Box } from "@material-ui/core"
import React from "react"
import LiveIndicator from "./LiveIndicator"
import HausmatesCount from "./HausmatesCount"
import { useSelector } from "react-redux"
import { RootState } from "../../redux/store"
import Controls from "./Controls/Controls"
import Shoutout from "../live/Shoutout"
import { isWellnessClass } from "../browse/types"


const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    width: "100%",
    height: "100%",
    zIndex: 1
  },
  overlay: {
    position: "absolute",
    width: "100vw",
    height: "56.25vw", /* height:width ratio = 9/16 = .5625  */
    maxHeight: "100vh",
    maxWidth: "177.78vh", /* 16/9 = 1.778 */
    margin: "auto",
    top: 0, bottom: 0, /* vertical center */
    left: 0, right: 0 /* horizontal center */
  },
  liveWithCountContainer: {
    position: "absolute",
    top: theme.spacing(1),
    right: theme.spacing(2.5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "end",
    [theme.breakpoints.down("md")]: {
      top: theme.spacing(2),
      right: theme.spacing(1.5)
    },
    [theme.breakpoints.down("sm")]: {
      top: theme.spacing(1),
      right: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      top: theme.spacing(0.5),
      right: theme.spacing(0.5)
    }
  },
  live: {
    justifySelf: "end"
  }
}))

export default function VideoOverlay() {
  const classes = useStyles()
  const numHausmates = useSelector((state: RootState) => state.live.numHausmates)
  const workout = useSelector((state: RootState) => state.solo.userWorkout?.workout)


  return <div className={classes.root}>
    <div className={classes.overlay}>
      <div className={classes.liveWithCountContainer}>
        <div className={classes.live}>
          <LiveIndicator mode={"live"}/>
        </div>
        {numHausmates > 0 && <Box mt={2}>
          <HausmatesCount numHausmates={numHausmates}/>
        </Box>}
      </div>
    </div>
    <Controls wellnessClass={workout !== undefined ? isWellnessClass(workout.categories) : false}/>
    {workout && !isWellnessClass(workout!.categories) && <Shoutout/>}
  </div>
}