import { makeStyles } from "@material-ui/core/styles"
import React from "react"
import { Typography } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignContent: "center",
    alignItems: "center",
    backgroundColor: "rgba(0,0,0,0.3)",
    padding: theme.spacing(0.5)
  },
  count: {
    fontSize: "2.2rem",
    marginTop: theme.spacing(0.5),
    lineHeight: 0,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.7rem",
      bottom: theme.spacing(1)
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.4rem",
      bottom: theme.spacing(1)
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: "1.2rem",
      bottom: theme.spacing(1)
    }
  },
  matesContainer: {
    marginLeft: theme.spacing(1),
    lineHeight: 0,
    [theme.breakpoints.down("md")]: {
      marginTop: theme.spacing(0.3)
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(0.2)
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: 0

    }
  }
}))

export interface Props {
  numHausmates: number
}

export default function HausmatesCount(props: Props) {
  const classes = useStyles()


  return <div className={classes.root}>
    <Typography className={classes.count} variant={"h4"}>{props.numHausmates}</Typography>
    <div className={classes.matesContainer}>
      <Typography variant={"subtitle1"} display={"block"}>hausmates</Typography>
      <Typography variant={"subtitle1"} display={"block"}>in class</Typography>
    </div>
  </div>
}