import React from "react"

import FullscreenIcon from "@material-ui/icons/Fullscreen"
import fscreen from "fscreen"
import FullscreenExitIcon from "@material-ui/icons/FullscreenExit"

import useFullScreenToggle from "../../../videochat/hooks/useFullScreenToggle/useFullScreenToggle"
import { Fab } from "@material-ui/core"
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import Tooltip from "@material-ui/core/Tooltip"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    fab: {
      margin: theme.spacing(1, 1, 1, 0)
    }
  })
)

export default function ToggleFullscreenButton() {
  const classes = useStyles()
  const [isFullScreen, toggleFullScreen] = useFullScreenToggle()

  return fscreen.fullscreenEnabled ? (
    <Tooltip
      title={isFullScreen ? "Exit Fullscreen" : "Enter Fullscreen"}
      placement="top"
      PopperProps={{ disablePortal: true }}
    >
      <Fab size={"small"} className={classes.fab} aria-label={`full screen`} onClick={toggleFullScreen}>
        {isFullScreen ? <FullscreenExitIcon/> : <FullscreenIcon/>}
      </Fab>
    </Tooltip>
  ) : null
}
