import WorkoutSEO from "../../components/WorkoutSEO"
import { AuthenticatedRoute } from "../routing/Routes"
import React from "react"
import Layout from "../../components/layout"
import SoloPlayer from "./Player/SoloPlayer"
import withLocation from "../../components/withLocation"


const SoloWrapper = ({ pageContext, search }: { pageContext: any, search: any }) => {
  const { tsId } = search
  return <Layout>
    <WorkoutSEO workoutVideo={pageContext}/>
    <AuthenticatedRoute component={SoloPlayer} id={pageContext.id} timeSlotId={tsId} showLoader={true}/>
  </Layout>
}

export default withLocation(SoloWrapper)