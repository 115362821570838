import { WorkoutVideo } from "../features/browse/types"
import SEO from "./seo"
import React from "react"

export interface Props {
  workoutVideo?: WorkoutVideo
  defaultTitle?: string
}

export default function WorkoutSEO({ workoutVideo, defaultTitle }: Props) {
  return <SEO title={workoutVideo?.pageTitle ?? defaultTitle} description={workoutVideo?.description}
              image={workoutVideo?.thumbnailUrl}/>
}