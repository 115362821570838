import { makeStyles } from "@material-ui/core/styles"
import { Difficulty, getDifficultyDisplay, WorkoutVideo } from "../browse/types"
import React from "react"
import Img from "gatsby-image"
import { Backdrop, Typography } from "@material-ui/core"
import EquipmentIndicator from "../browse/EquipmentIndicator/EquipmentIndicator"
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled"

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "black",
    position: "absolute"
  },

  backdrop: {
    zIndex: 1,
    backgroundColor: "rgba(0, 0, 0, 0.75)"
  },

  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    position: "absolute"
  },

  subText: {
    opacity: 0.4
  },

  tint: {
    width: "100%",
    height: "100%",
    position: "absolute",
    bottom: "0",
    left: "0",
    zIndex: 0,
    "-webkit-backdrop-filter": "blur(8px)",
    "backdrop-filter": "blur(8px)"
  },

  metaContainer: {
    display: "flex",
    marginTop: theme.spacing(0.5),
    alignItems: "center",
    justifyContent: "center"
  },
  difficulty: {
    whiteSpace: "pre-wrap"
  },
  countdown: {
    fontSize: "5rem",
    lineHeight: "1",
    marginTop: theme.spacing(1),
    [theme.breakpoints.up("xl")]: {
      fontSize: "5.5rem"
    }
  },
  startIn: {
    marginTop: theme.spacing(8)
  },
  waitMessage: {
    fontSize: "2.5rem",
    width: "40%",
    marginTop: theme.spacing(8),
    marginLeft: "auto",
    marginRight: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "70%",
      fontSize: "1.5rem"
    },
    [theme.breakpoints.up("xl")]: {
      fontSize: "3rem"
    }
  },
  hausmates: {
    whiteSpace: "pre-wrap"
  },
  playContainer: {
    marginTop: theme.spacing(8),
    marginLeft: "auto",
    marginRight: "auto",
    width: "100px",
    "&:hover": {
      cursor: "pointer"
    }
  }

}))

export interface Props {
  workout: WorkoutVideo
  countdown?: string | null
  waitMessage?: string | null
  showPlay: boolean
  numHausmates: number
  playClicked: () => void
}

export function ClassWaitOverlay(props: Props) {
  const classes = useStyles()

  return <div className={classes.root}>
    {props.workout.localImage == null && <img className={classes.image} src={props.workout.thumbnailUrl}/>}
    {props.workout.localImage != null && <Img style={{ position: `absolute` }} className={classes.image}
                                              fluid={props.workout.localImage.childImageSharp.fluid}/>}

    <div className={classes.tint}/>
    <Backdrop className={classes.backdrop} open={true}>
      <div style={{ width: "100%" }}>

        <Typography variant={"h2"} align={"center"}>{props.workout.title}</Typography>

        <div className={classes.metaContainer}>
          <EquipmentIndicator equipments={props.workout.equipments} type={"large"}/>
          <Typography className={classes.difficulty} variant={"body2"}
                      align={"center"}> • {getDifficultyDisplay(props.workout.mainDifficulty as Difficulty)}</Typography>
          {props.numHausmates > 0 &&
          <Typography className={classes.hausmates} variant={"body2"} align={"center"}> • {props.numHausmates} hausmates
            here</Typography>}
        </div>


        {props.countdown &&
        <div className={classes.startIn}>
          <Typography variant={"body1"} align={"center"}>Class is starting soon!</Typography>
          <Typography variant={"h1"} align={"center"} className={classes.countdown}>{props.countdown}</Typography>
        </div>}


        {props.waitMessage &&
        <div>
          <Typography variant={"h1"} align={"center"} className={classes.waitMessage}>{props.waitMessage}</Typography>
        </div>}

        {props.showPlay &&
        <div className={classes.playContainer} onClick={props.playClicked}>
          <PlayCircleFilledIcon style={{ fontSize: "5rem" }}/>
        </div>}

      </div>
    </Backdrop>
  </div>


}